var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      staticClass:
        "buscador-container d-flex flex-row-reverse position-relative",
      attrs: { autocomplete: "off" },
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submit.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "mdb-tooltip",
        {
          attrs: {
            maxWidth: 130,
            disabled: !_vm.disabled,
            sm: "",
            options: { placement: "top" },
          },
        },
        [
          _c("span", { attrs: { slot: "tip" }, slot: "tip" }, [
            _vm._v(_vm._s(_vm.tooltip)),
          ]),
          _c(
            "button",
            {
              staticClass:
                "px-2 px-md-4 py-3 text-white rounded-end-pill border-0",
              class: { disabled2: _vm.disabled },
              staticStyle: {
                "background-color": "#850b0b",
                "font-size": "15px",
                "font-weight": "700",
              },
              attrs: { slot: "reference", color: "secondary", type: "submit" },
              slot: "reference",
            },
            [_c("span", [_vm._v(_vm._s(_vm.$t("default.SEARCH")))])]
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "flex-grow-1 position-relative bg-light" },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.value,
                expression: "value",
              },
            ],
            staticClass: "border-0 input w-100 h-100",
            attrs: {
              placeholder: _vm.$t("default.FILTER.DESTINY_OR_ACTIVITY"),
              size: "sm",
              id: "search",
              name: "search",
              type: "text",
              autocomplete: "off",
              autocorrect: "off",
              autocapitalize: "none",
              spellcheck: "false",
            },
            domProps: { value: _vm.value },
            on: {
              focus: () => _vm.focusFunction(),
              blur: function ($event) {
                _vm.isFocused = false
              },
              input: function ($event) {
                if ($event.target.composing) return
                _vm.value = $event.target.value
              },
            },
          }),
          _c("transition", [
            _vm.warningInfo.show
              ? _c(
                  "h5",
                  {
                    staticClass:
                      "pill-warning mx-1 mx-md-2 position-absolute w-100 text-start p-2 rounded rounded-pill text-white m-0",
                    staticStyle: {
                      "background-color": "var(--spainhn-rojo-corporativo)",
                      top: "70%",
                      "max-width": "95%",
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.warningInfo?.message) + " ")]
                )
              : _vm._e(),
          ]),
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "mdb-dropdown",
            {
              ref: "main",
              staticStyle: {
                height: "100%",
                width: "100%",
                "background-color": "transparent !important",
                "box-shadow": "none !important",
              },
              attrs: { dropright: "", multiLevel: "" },
            },
            [
              _c(
                "mdb-dropdown-toggle",
                {
                  ref: "dropdownButton",
                  staticClass:
                    "rounded-start-pill pe-2 dropdownBtn d-flex align-items-center",
                  class: !_vm.changeColorBtn
                    ? "bg-light-btn"
                    : "spainhn-gray-subheader",
                  staticStyle: {
                    width: "100%",
                    height: "100% !important",
                    border: "#850b0b 1px !important",
                  },
                  attrs: { slot: "toggle", hover: "" },
                  slot: "toggle",
                },
                [
                  _c("img", {
                    class: { "d-none": _vm.changeColorBtn },
                    staticStyle: { width: "20px", height: "20px" },
                    attrs: { src: "/calendar.svg", alt: "icon" },
                  }),
                  _c("font-awesome-icon", {
                    class: { "d-none": !_vm.changeColorBtn },
                    staticStyle: { width: "20px", height: "20px" },
                    attrs: { icon: "fa-calendar-check" },
                  }),
                ],
                1
              ),
              _c(
                "mdb-dropdown-menu",
                { staticClass: "shadow" },
                [_vm._t("default")],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "fade" } },
        [
          _c(
            "ais-instant-search",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isFocused && _vm.value.length > 2,
                  expression: "isFocused && value.length > 2",
                },
              ],
              staticClass: "mt-3 rounded rounded-5 p-2 color-corp",
              attrs: {
                "search-client": _vm.searchClient,
                "index-name": "monumentos",
              },
              on: {
                focusin: function ($event) {
                  _vm.isFocused = true
                },
              },
            },
            [
              _c("ais-configure", {
                attrs: {
                  hitsPerPage: 5,
                  filters: "activo:true AND NOT tipoDeMonumento:Parador",
                },
              }),
              _c("ais-search-box", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: false,
                    expression: "false",
                  },
                ],
                attrs: { value: _vm.value },
              }),
              _c("header", { staticClass: "m-0 p-0" }, [
                _c("h5", { staticClass: "section-title" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        !_vm.value
                          ? "Explora Nuestro Contenido"
                          : _vm.$t(
                              "default.HOME_BROWSER_ALGOLIA_HEADER_RESULTS"
                            )
                      ) +
                      " "
                  ),
                ]),
              ]),
              _c("hr", { staticClass: "my-2" }),
              _c(
                "div",
                {
                  staticClass:
                    "d-block text-start text-sm-center d-md-flex flex-column justify-content-start algolia-body",
                },
                [
                  _c(
                    "section",
                    { staticClass: "mt-sm-0 p-0 ais-index-1" },
                    [
                      _c(
                        "ais-index",
                        { attrs: { "index-name": "visitas" } },
                        [
                          _c("ais-state-results", {
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ results: { hits } }) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: hits.length !== 0,
                                            expression: "hits.length !== 0",
                                          },
                                        ],
                                      },
                                      [
                                        _c(
                                          "h5",
                                          { staticClass: "mb-3 section-title" },
                                          [
                                            _c("font-awesome-icon", {
                                              staticClass: "text-danger",
                                              attrs: {
                                                icon: "fa-chess-rook",
                                                size: "1x",
                                              },
                                            }),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "default.HOME_BROWSER_ALGOLIA_HEADER_FREE_VISITS"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("ais-configure", {
                            attrs: {
                              filters:
                                "monumentoActivo:true AND web:true AND (tipoFiltro:'Visita Libre' OR NOT _exists_:tipoFiltro)",
                            },
                          }),
                          _c("ais-hits", {
                            scopedSlots: _vm._u([
                              {
                                key: "item",
                                fn: function ({ item }) {
                                  return [
                                    _c(
                                      "router-link",
                                      {
                                        staticClass: "color-corp",
                                        attrs: {
                                          tag: "div",
                                          to: {
                                            name: "Detalle de Entradas",
                                            params: {
                                              monumentoNombre: _vm.encodeUrl(
                                                item.castilloNombres?.[0]?.texto
                                              ),
                                              entradaId: item.objectID,
                                            },
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "p",
                                          {
                                            staticClass: "m-0",
                                            staticStyle: {
                                              "line-height": "20px",
                                            },
                                          },
                                          [
                                            _c("strong", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getNameInLanguage(
                                                    item.nombres,
                                                    "texto"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _c("span", [
                                          _vm._v(_vm._s(item.provincia)),
                                        ]),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "section",
                    { staticClass: "mt-sm-0 p-0 ais-index-1" },
                    [
                      _c(
                        "ais-index",
                        { attrs: { "index-name": "visitas" } },
                        [
                          _c("ais-state-results", {
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ results: { hits } }) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: hits.length !== 0,
                                            expression: "hits.length !== 0",
                                          },
                                        ],
                                      },
                                      [
                                        _c(
                                          "h5",
                                          { staticClass: "mb-3 section-title" },
                                          [
                                            _c("font-awesome-icon", {
                                              staticClass: "text-danger",
                                              attrs: {
                                                icon: "fa-chess-rook",
                                                size: "1x",
                                              },
                                            }),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "default.HOME_BROWSER_ALGOLIA_HEADER_FREE_VISITS_AUDIO_GUIDES"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("ais-configure", {
                            attrs: {
                              filters:
                                "monumentoActivo:true AND web:true AND tipoFiltro:'Visita Libre Audioguiada'",
                            },
                          }),
                          _c("ais-hits", {
                            scopedSlots: _vm._u([
                              {
                                key: "item",
                                fn: function ({ item }) {
                                  return [
                                    _c(
                                      "router-link",
                                      {
                                        staticClass: "color-corp",
                                        attrs: {
                                          tag: "div",
                                          to: {
                                            name: "Detalle de Entradas",
                                            params: {
                                              monumentoNombre: _vm.encodeUrl(
                                                item.castilloNombres?.[0]?.texto
                                              ),
                                              entradaId: item.objectID,
                                            },
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "p",
                                          {
                                            staticClass: "m-0",
                                            staticStyle: {
                                              "line-height": "20px",
                                            },
                                          },
                                          [
                                            _c("strong", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getNameInLanguage(
                                                    item.nombres,
                                                    "texto"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _c("span", [
                                          _vm._v(_vm._s(item.provincia)),
                                        ]),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "section",
                    { staticClass: "mt-sm-0 p-0 ais-index-1" },
                    [
                      _c(
                        "ais-index",
                        { attrs: { "index-name": "visitas" } },
                        [
                          _c("ais-state-results", {
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ results: { hits } }) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: hits.length !== 0,
                                            expression: "hits.length !== 0",
                                          },
                                        ],
                                      },
                                      [
                                        _c(
                                          "h5",
                                          { staticClass: "mb-3 section-title" },
                                          [
                                            _c("font-awesome-icon", {
                                              staticClass: "text-danger",
                                              attrs: {
                                                icon: "fa-chess-rook",
                                                size: "1x",
                                              },
                                            }),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "default.HOME_BROWSER_ALGOLIA_HEADER_GUIDED_VISITS"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("ais-configure", {
                            attrs: {
                              filters:
                                "monumentoActivo:true AND web:true AND tipoFiltro: 'Visita Guiada'",
                            },
                          }),
                          _c("ais-hits", {
                            scopedSlots: _vm._u([
                              {
                                key: "item",
                                fn: function ({ item }) {
                                  return [
                                    _c(
                                      "router-link",
                                      {
                                        staticClass: "color-corp",
                                        attrs: {
                                          tag: "div",
                                          to: {
                                            name: "Detalle de Entradas",
                                            params: {
                                              monumentoNombre: _vm.encodeUrl(
                                                item.castilloNombres?.[0]?.texto
                                              ),
                                              entradaId: item.objectID,
                                            },
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "p",
                                          {
                                            staticClass: "m-0",
                                            staticStyle: {
                                              "line-height": "20px",
                                            },
                                          },
                                          [
                                            _c("strong", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getNameInLanguage(
                                                    item.nombres,
                                                    "texto"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _c("span", [
                                          _vm._v(_vm._s(item.provincia)),
                                        ]),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "section",
                    { staticClass: "mt-sm-0 p-0 ais-index-1" },
                    [
                      _c(
                        "ais-index",
                        { attrs: { "index-name": "visitas" } },
                        [
                          _c("ais-state-results", {
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ results: { hits } }) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: hits.length !== 0,
                                            expression: "hits.length !== 0",
                                          },
                                        ],
                                      },
                                      [
                                        _c(
                                          "h5",
                                          { staticClass: "mb-3 section-title" },
                                          [
                                            _c("font-awesome-icon", {
                                              staticClass: "text-danger",
                                              attrs: {
                                                icon: "fa-chess-rook",
                                                size: "1x",
                                              },
                                            }),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "default.HOME_BROWSER_ALGOLIA_DRAMATIZED_VISIT"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("ais-configure", {
                            attrs: {
                              filters:
                                "monumentoActivo:true AND web:true AND tipoFiltro:'Visita Teatralizada'",
                            },
                          }),
                          _c("ais-hits", {
                            scopedSlots: _vm._u([
                              {
                                key: "item",
                                fn: function ({ item }) {
                                  return [
                                    _c(
                                      "router-link",
                                      {
                                        staticClass: "color-corp",
                                        attrs: {
                                          tag: "div",
                                          to: {
                                            name: "Detalle de Entradas",
                                            params: {
                                              monumentoNombre: _vm.encodeUrl(
                                                item.castilloNombres?.[0]?.texto
                                              ),
                                              entradaId: item.objectID,
                                            },
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "p",
                                          {
                                            staticClass: "m-0",
                                            staticStyle: {
                                              "line-height": "20px",
                                            },
                                          },
                                          [
                                            _c("strong", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getNameInLanguage(
                                                    item.nombres,
                                                    "texto"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _c("span", [
                                          _vm._v(_vm._s(item.provincia)),
                                        ]),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "section",
                    { staticClass: "mt-sm-0 p-0 ais-index-1" },
                    [
                      _c(
                        "ais-index",
                        { attrs: { "index-name": "visitas" } },
                        [
                          _c("ais-state-results", {
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ results: { hits } }) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: hits.length !== 0,
                                            expression: "hits.length !== 0",
                                          },
                                        ],
                                      },
                                      [
                                        _c(
                                          "h5",
                                          { staticClass: "mb-3 section-title" },
                                          [
                                            _c("font-awesome-icon", {
                                              staticClass: "text-danger",
                                              attrs: {
                                                icon: "fa-chess-rook",
                                                size: "1x",
                                              },
                                            }),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "default.HOME_BROWSER_ALGOLIA_UNIQUE_VISITS"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("ais-configure", {
                            attrs: {
                              filters:
                                "monumentoActivo:true AND web:true AND tipoFiltro:'Evento Singular'",
                            },
                          }),
                          _c("ais-hits", {
                            scopedSlots: _vm._u([
                              {
                                key: "item",
                                fn: function ({ item }) {
                                  return [
                                    _c(
                                      "router-link",
                                      {
                                        staticClass: "color-corp",
                                        attrs: {
                                          tag: "div",
                                          to: {
                                            name: "Detalle de Entradas",
                                            params: {
                                              monumentoNombre: _vm.encodeUrl(
                                                item.castilloNombres?.[0]?.texto
                                              ),
                                              entradaId: item.objectID,
                                            },
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "p",
                                          {
                                            staticClass: "m-0",
                                            staticStyle: {
                                              "line-height": "20px",
                                            },
                                          },
                                          [
                                            _c("strong", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getNameInLanguage(
                                                    item.nombres,
                                                    "texto"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _c("span", [
                                          _vm._v(_vm._s(item.provincia)),
                                        ]),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "section",
                    { staticClass: "mt-sm-0 p-0 ais-index-1" },
                    [
                      _c(
                        "ais-index",
                        { attrs: { "index-name": "eventos" } },
                        [
                          _c("ais-state-results", {
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ results: { hits } }) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: hits.length !== 0,
                                            expression: "hits.length !== 0",
                                          },
                                        ],
                                      },
                                      [
                                        _c(
                                          "h5",
                                          { staticClass: "mb-3 section-title" },
                                          [
                                            _c("font-awesome-icon", {
                                              staticClass: "text-danger",
                                              attrs: {
                                                icon: "fa-chess-rook",
                                                size: "1x",
                                              },
                                            }),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "default.HOME_BROWSER_ALGOLIA_EVENTS"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("ais-configure", {
                            attrs: {
                              filters: "monumentoActivo:true AND web:true",
                            },
                          }),
                          _c("ais-hits", {
                            scopedSlots: _vm._u([
                              {
                                key: "item",
                                fn: function ({ item }) {
                                  return [
                                    _c(
                                      "router-link",
                                      {
                                        staticClass: "color-corp",
                                        attrs: {
                                          tag: "div",
                                          to: {
                                            name: "Detalle de Entradas",
                                            params: {
                                              monumentoNombre: _vm.encodeUrl(
                                                item.castilloNombres?.[0]?.texto
                                              ),
                                              entradaId: item.objectID,
                                            },
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "p",
                                          {
                                            staticClass: "m-0",
                                            staticStyle: {
                                              "line-height": "20px",
                                            },
                                          },
                                          [
                                            _c("strong", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getNameInLanguage(
                                                    item.nombres,
                                                    "texto"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _c("span", [
                                          _vm._v(_vm._s(item.provincia)),
                                        ]),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "section",
                    [
                      _c(
                        "ais-index",
                        { attrs: { "index-name": "paquetes_monumentos" } },
                        [
                          _c("ais-state-results", {
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ results: { hits } }) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: hits.length !== 0,
                                            expression: "hits.length !== 0",
                                          },
                                        ],
                                      },
                                      [
                                        _c(
                                          "h5",
                                          { staticClass: "mb-3 section-title" },
                                          [
                                            _c("font-awesome-icon", {
                                              staticClass: "text-danger",
                                              attrs: {
                                                icon: "fa-chess-rook",
                                                size: "1x",
                                              },
                                            }),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "default.HOME_NUESTROS_PAQUETES"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("ais-configure", {
                            attrs: { filters: "monumentoActivo:true" },
                          }),
                          _c("ais-hits", {
                            scopedSlots: _vm._u([
                              {
                                key: "item",
                                fn: function ({ item }) {
                                  return [
                                    _c(
                                      "router-link",
                                      {
                                        staticClass: "color-corp",
                                        attrs: {
                                          tag: "div",
                                          to: {
                                            name: "MicrositioPaqueteMonumento",
                                            params: {
                                              paqueteNombre: _vm.encodeUrl(
                                                item.name
                                              ),
                                              paqueteId: item.objectID,
                                            },
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "p",
                                          {
                                            staticClass: "m-0",
                                            staticStyle: {
                                              "line-height": "20px",
                                            },
                                          },
                                          [
                                            _c("strong", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getNameInLanguage(
                                                    item.nombres,
                                                    "texto"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _c("span", [
                                          _vm._v(_vm._s(item.provincia)),
                                        ]),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "section",
                    { staticClass: "mt-sm-0 p-0 ais-index-1" },
                    [
                      _c("ais-state-results", {
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ results: { hits } }) {
                              return [
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: hits.length !== 0,
                                        expression: "hits.length !== 0",
                                      },
                                    ],
                                  },
                                  [
                                    _c(
                                      "h5",
                                      { staticClass: "mb-3 section-title" },
                                      [
                                        _c("font-awesome-icon", {
                                          staticClass: "text-danger",
                                          attrs: {
                                            icon: "fa-chess-rook",
                                            size: "1x",
                                          },
                                        }),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "default.HOME_BROWSER_ALGOLIA_KNOW_OUR_MONUMENTS"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("ais-hits", {
                        scopedSlots: _vm._u([
                          {
                            key: "item",
                            fn: function ({ item }) {
                              return [
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "color-corp",
                                    attrs: {
                                      tag: "div",
                                      to: {
                                        name: "Micro_Sitios",
                                        params: {
                                          monumentoNombre: _vm.encodeUrl(
                                            item.nombre
                                          ),
                                        },
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticClass: "m-0",
                                        staticStyle: {
                                          "line-height": "20px !important",
                                        },
                                      },
                                      [
                                        _c("strong", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getNameInLanguage(
                                                item.nombres,
                                                "texto"
                                              )
                                            )
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _c("span", [
                                      _vm._v(_vm._s(item.provincia)),
                                    ]),
                                  ]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "section",
                    { staticClass: "mt-sm-0 p-0 ais-index-1" },
                    [
                      _c(
                        "ais-index",
                        { attrs: { "index-name": "experiencias" } },
                        [
                          _c("ais-state-results", {
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ results: { hits } }) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: hits.length !== 0,
                                            expression: "hits.length !== 0",
                                          },
                                        ],
                                      },
                                      [
                                        _c(
                                          "h5",
                                          { staticClass: "mb-3 section-title" },
                                          [
                                            _c("font-awesome-icon", {
                                              staticClass: "text-danger",
                                              attrs: {
                                                icon: "fa-chess-rook",
                                                size: "1x",
                                              },
                                            }),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "default.HOME_BROWSER_ALGOLIA_EXPERIENCES"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("ais-configure", {
                            attrs: {
                              filters: "monumentoActivo:true AND web:true",
                            },
                          }),
                          _c("ais-hits", {
                            scopedSlots: _vm._u([
                              {
                                key: "item",
                                fn: function ({ item }) {
                                  return [
                                    _c(
                                      "router-link",
                                      {
                                        staticClass: "color-corp",
                                        attrs: {
                                          tag: "div",
                                          to: {
                                            name: "Detalle de Entradas",
                                            params: {
                                              monumentoNombre: _vm.encodeUrl(
                                                item.castilloNombres?.[0]?.texto
                                              ),
                                              entradaId: item.objectID,
                                            },
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "p",
                                          {
                                            staticClass: "m-0",
                                            staticStyle: {
                                              "line-height": "20px",
                                            },
                                          },
                                          [
                                            _c("strong", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getNameInLanguage(
                                                    item.nombres,
                                                    "texto"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _c("span", [
                                          _vm._v(_vm._s(item.provincia)),
                                        ]),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "section",
                    { staticClass: "mt-sm-0 p-0 ais-index-1" },
                    [
                      _c(
                        "ais-index",
                        { attrs: { "index-name": "rutas" } },
                        [
                          _c("ais-state-results", {
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ results: { hits } }) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: hits.length !== 0,
                                            expression: "hits.length !== 0",
                                          },
                                        ],
                                      },
                                      [
                                        _c(
                                          "h5",
                                          { staticClass: "mb-3 section-title" },
                                          [
                                            _c("font-awesome-icon", {
                                              staticClass: "text-danger",
                                              attrs: {
                                                icon: "fa-chess-rook",
                                                size: "1x",
                                              },
                                            }),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "default.HOME_BROWSER_ALGOLIA_KNOW_OUR_RUTES"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("ais-configure", {
                            attrs: { filters: "activa:true" },
                          }),
                          _c("ais-hits", {
                            scopedSlots: _vm._u([
                              {
                                key: "item",
                                fn: function ({ item }) {
                                  return [
                                    _c(
                                      "router-link",
                                      {
                                        staticClass: "color-corp",
                                        attrs: {
                                          tag: "div",
                                          to: {
                                            name: "Ruta",
                                            params: {
                                              rutaId: item.objectID,
                                            },
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "p",
                                          {
                                            staticClass: "m-0",
                                            staticStyle: {
                                              "line-height": "20px",
                                            },
                                          },
                                          [
                                            _c("strong", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getNameInLanguage(
                                                    item.nombres,
                                                    "texto"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              item.comunidadesPorMonumento.join(
                                                ","
                                              )
                                            )
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }